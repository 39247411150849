import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "./article.scss"

const Article = ({ image, slug, title, date, readingTime, description }) => {
  return (
    <Link to={slug} className="post-article">
      <article>
        <Img fluid={image} />
        <div className="content">
          <h2>{title}</h2>
          <small className="date"> {date} </small>{" "}
          <small className="bullet">&bull;</small>
          <small className="read-time">
            {" "}
            {readingTime.toFixed()}min de leitura
          </small>
          <small className="description">{description}</small>
        </div>
      </article>
    </Link>
  )
}

export default Article
