import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Article from "../components/Article"
import SEO from "../components/seo"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges } = data.allMdx

  return (
    <Layout>
      <SEO title={tag + "| Leituras por Flavia Nunes"} />
      <Header title={"Todos os posts de " + tag} subtitle="" />
      <section className="article-container">
        {edges.map(({ node }) => {
          const post = {
            ...node.frontmatter,
            ...node.fields,
            readingTime: node.timeToRead,
            image: node.frontmatter.cover.childImageSharp.fluid,
          }
          return (
            <div key={node.fields.slug}>
              <Article {...post} />
            </div>
          )
        })}
      </section>
    </Layout>
  )
}

export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            description
            cover {
              childImageSharp {
                fluid(maxWidth: 820, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`
